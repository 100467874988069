<template>
  <div>
    <a-button icon="book" @click="showModal" style="margin-bottom: 20px">
      {{ $t("action.ajouter") }}
    </a-button>
    <a-popconfirm
      :title="$t('decisionConseil.confirmDefault')"
      @confirm="() => setDefaultDecisions()"
    >
      <a-button style="position: absolute; right: 40px">
        {{ $t("decisionConseil.default") }}
      </a-button>
    </a-popconfirm>

    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :footer="false"
    >
      <a-form :form="form" @submit="addCertification">
        <a-form-item :label="$t('decisionConseil.decision')">
          <a-input
            placeholder="e.g: يرتقي"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.nom'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div class="row">
          <div class="col">
            <a-form-item :label="$t('certification.min')">
              <a-input-number
                :min="0"
                :max="20"
                style="width: 80%"
                :placeholder="$t('certification.min')"
                v-decorator="[
                  'min',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.min'),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (
                            Number(this.form.getFieldValue('max')) <
                            Number(value)
                          ) {
                            callback($t('certification.minError'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col">
            <a-form-item :label="$t('certification.max')">
              <a-input-number
                :min="0"
                :max="20"
                style="width: 80%"
                :placeholder="$t('certification.max')"
                v-decorator="[
                  'max',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.max'),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (
                            Number(this.form.getFieldValue('min')) >
                            Number(value)
                          ) {
                            callback($t('certification.maxError'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="form-actions mt-0">
          <a-button
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingClasse"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <div class="card">
      <a-table
        :loading="tableLeading"
        :rowKey="'_id'"
        :columns="columns"
        :data-source="data"
        :pagination="false"
      >
        <template slot="name" slot-scope="text, record">
          <div key="name">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record.id, 'name')"
            />
            <template v-else>
              <a-tag>{{ text }}</a-tag>
            </template>
          </div>
        </template>
        <template slot="min" slot-scope="text, record">
          <div key="min">
            <a-input-number
              :min="0"
              :max="20"
              v-if="record.editable"
              style="margin: -5px 0"
              :default-value="text"
              @change="(value) => handleChange(value, record.id, 'min')"
            />
            <template v-else>
              <a-tag color="red">{{ text }}</a-tag></template
            >
          </div>
        </template>
        <template slot="max" slot-scope="text, record">
          <div key="max">
            <a-input-number
              :min="0"
              :max="20"
              v-if="record.editable"
              style="margin: -5px 0"
              :default-value="text"
              @change="(value) => handleChange(value, record.id, 'max')"
            />
            <template v-else>
              <a-tag color="green">{{ text }}</a-tag>
            </template>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a-button
                size="small"
                style="margin-left: 5px; margin-right: 5px"
                type="primary"
                @click="() => save(record.id)"
              >
                {{ $t("action.enregistrer") }}
              </a-button>
              <a-button size="small" type="danger">
                <a-popconfirm
                  :title="$t('all.sureToCancelAlert')"
                  @confirm="() => cancel(record.id)"
                >
                  <a>{{ $t("action.annuler") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
            <span v-else>
              <a-button
                type="primary"
                :disabled="editingKey !== ''"
                @click="() => edit(record.id)"
              >
                <a-icon type="edit" />{{ $t("action.modifier") }}
              </a-button>
            </span>
          </div>
        </span>
        <span slot="operationSupprimer" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a-button :disabled="editingKey !== ''" type="danger">
                <a-popconfirm
                  :title="$t('requis.supp')"
                  @confirm="() => supp(record.id)"
                >
                  <a>{{ $t("action.supprimer") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
          </div>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";

export default {
  name: "decisionConseils",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "decisionConseilForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Décisions du conseil",
    });

    await apiClient
      .get("/decisionConseil")
      .then((res) => {
        let decisionConseilsData = [...res.data];
        decisionConseilsData = decisionConseilsData.map((elem) => {
          return {
            ...elem,
            key: elem.id,
          };
        });
        this.data = decisionConseilsData;
        this.cacheData = decisionConseilsData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) => {
        this.$message.error(this.$t("error.erreur"));
        this.tableLeading = false;
      });
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("decisionConseil.decision"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.$t("certification.min"),
          dataIndex: "min",
          key: "min",
          scopedSlots: { customRender: "min" },
          sorter: (a, b) => a.min - b.min,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("certification.max"),
          dataIndex: "max",
          key: "max",
          scopedSlots: { customRender: "max" },
          sorter: (a, b) => a.max - b.max,
          sortDirections: ["descend", "ascend"],
        },

        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
      defaultData: [
        { id: 1, key: 1, min: 10, max: 20, name: "يرتقي" },
        { id: 2, key: 2, min: 9, max: 9.99, name: "يرتقي بالإسعاف" },
        { id: 3, key: 3, min: 0, max: 8.99, name: "يرسب" },
      ],
    };
  },
  computed: mapState(["settings", "user"]),

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addCertification(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/decisionConseil", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.decisionConseilAjout"));
              res.data.key = res.data.id;
              this.data.push(res.data);
              this.cacheData = this.data.map((item) => ({ ...item }));
              this.handleCancel();
            })
            .catch((e) => this.$message.error(this.$t("error.erreur")))
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = { ...target };
      delete updates.key;
      delete updates.id;
      delete updates.editable;
      apiClient
        .patch("/decisionConseil/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.decisionConseilMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.modif"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .delete("/decisionConseil/" + key)
        .then(() => {
          this.$message.success(this.$t("success.decisionConseilSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.supp")));
    },
    setDefaultDecisions() {
      this.data = this.defaultData.map((item) => ({ ...item }));
      this.cacheData = this.defaultData.map((item) => ({ ...item }));

      const decisionConseilParams = this.data.map((c) => ({
        id: c.id,
        name: c.name,
        min: c.min,
        max: c.max,
      }));

      apiClient
        .patch("/buildings/" + this.settings.activeBuildingId, {
          data: { decisionConseilParams },
        })
        .then((res) => {
          this.$message.success(this.$t("decisionConseil.defaultDone"));
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
        });
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
>
