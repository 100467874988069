<template>
  <div>
    <a-alert
      :message="$t('scolarite.listeModulesVerySpec')"
      :description="$t('scolarite.infoModuleVerySpec')"
      type="success"
      show-icon
      closable
    />
    <div class="row">
      <div class="col-md-6">
        <a-select
          size="large"
          :default-value="defaultLevel[schoolType]"
          @change="handleModuleChange"
          class="pb-2 pt-2"
          style="width: 100%"
        >
          <a-select-option
            v-for="(value, name, index) in levelSelectData[schoolType]"
            :key="index"
            :value="name"
          >
            {{ value }}</a-select-option
          >
        </a-select>
      </div>
      <div class="col-md-6">
        <a-button
          type="primary"
          icon="book"
          class="mx-auto my-2"
          @click="showAddModal"
          style="width: 100%"
        >
          {{ $t("action.ajouter") }}
        </a-button>
      </div>
    </div>

    <a-modal
      v-model="addSubsubjectVisible"
      @cancel="closeAddSubsubject"
      :title="$t('all.enterInformationBelow')"
      :footer="false"
    >
      <a-form :form="subsubjectForm" @submit="addSubsubject">
        <a-form-item :label="$t('scolarite.nom')">
          <a-input
            :placeholder="$t('scolarite.nom')"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.nom'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div class="form-actions mt-0">
          <a-button
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button
            key="back"
            @click="closeAddSubsubject"
            :disabled="loadingClasse"
          >
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>

    <a-modal
      v-model="subsubjectVisible"
      :title="$t('scolarite.addNewSubsubject')"
      :footer="false"
      width="40%"
    >
      <a-button
        type="dashed"
        size="large"
        icon="book"
        @click="
          () => {
            addSubsubjectVisible = true;
          }
        "
        style="margin-bottom: 20px"
      >
        {{ $t("action.ajouter") }}
      </a-button>
      <a-table
        :loading="tableLoadingSubsubjects"
        :rowKey="'_id'"
        :columns="subsubjectsColumns"
        :data-source="listSubsubjects"
        :pagination="true"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="name" slot-scope="text, record">
          <div key="name">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="
                (e) =>
                  handleChangeSubsubject(e.target.value, record._id, 'name')
              "
            />
            <template v-else> {{ text }} </template>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a-button
                size="small"
                type="primary"
                @click="() => saveSubsubject(record._id)"
              >
                {{ $t("action.enregistrer") }}
              </a-button>
              <a-button size="small" type="danger">
                <a-popconfirm
                  :title="$t('all.sureToCancelAlert')"
                  @confirm="() => cancelSubsubject(record._id)"
                >
                  <a> {{ $t("action.annuler") }} </a>
                </a-popconfirm>
              </a-button>
            </span>
            <span v-else>
              <a-button
                type="primary"
                :disabled="subsubjectEditingKey !== ''"
                @click="() => editSubsubject(record._id)"
              >
                <a-icon type="edit" />{{ $t("action.modifier") }}
              </a-button>
            </span>
          </div>
        </span>
      </a-table>
      <div class="form-actions mt-0">
        <a-button
          key="back"
          @click="
            () => {
              subsubjectVisible = false;
            }
          "
        >
          {{ $t("action.annuler") }}
        </a-button>
      </div>
    </a-modal>

    <div class="card">
      <!-- add module form -->
      <a-modal
        v-model="visibleAddModal"
        @cancel="handleCancel('add')"
        :title="$t('scolarite.ajouterModule')"
        :footer="false"
        width="50%"
      >
        <a-form :form="addForm" @submit="addModule">
          <a-row style="width: 100%">
            <a-col :span="12">
              <a-form-item :label="$t('scolarite.nom')">
                <a-input
                  :placeholder="$t('scolarite.nom')"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.nom'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item :label="$t('scolarite.coefficient')">
                <a-input-number
                  style="width: 100%; margin-left: 10px"
                  :placeholder="$t('scolarite.coefficient')"
                  v-decorator="[
                    'coef',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.coefficient'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item :label="$t('scolarite.listeMatieres')">
            <div
              class="card"
              v-if="selectedSubjects.length == 0"
              style="width: 100%; padding: 10px"
            >
              <a-empty :image="simpleImage" />
            </div>
            <div v-else>
              <div
                v-for="(item, index) in selectedSubjects"
                :key="index"
                class="card"
                style="width: 100%; padding: 10px"
              >
                <a-label>{{ $t("emploi.matiere") }}: </a-label>
                <a-row>
                  <a-col :span="12">
                    <a-select
                      label-in-value
                      :placeholder="$t('emploi.choisirMatiere')"
                      :default-value="item.subject"
                      v-model="item.subject"
                      @change="
                        (val) => {
                          item.subject = val;
                        }
                      "
                    >
                      <a-select-option
                        v-for="subject in unusedSubjects"
                        :key="subject._id"
                        :value="subject._id"
                      >
                        {{ subject.name }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="10">
                    <a-input-number
                      style="width: 100%"
                      class="ml-2"
                      :value="item.coef"
                      v-model="item.coef"
                      :placeholder="$t('scolarite.coefficient')"
                  /></a-col>
                  <a-col :span="2">
                    <a-button
                      type="danger"
                      class="ml-4"
                      shape="circle"
                      icon="delete"
                      @click="
                        () => {
                          selectedSubjects.splice(index, 1);
                        }
                      "
                    />
                  </a-col>
                </a-row>
                <a-label>{{ $t("scolarite.subsubjects") }}:</a-label>
                <div
                  class="card"
                  v-if="item.subsubjects && item.subsubjects.length > 0"
                  style="padding: 10px; margin-bottom: 10px"
                >
                  <a-row>
                    <a-col :span="16">
                      <a-label>{{ $t("scolarite.subsubject") }}: </a-label>
                    </a-col>
                    <a-col :span="6">
                      <a-label>{{ $t("scolarite.mark") }}: </a-label>
                    </a-col>
                  </a-row>
                  <a-row v-for="(item2, i) in item.subsubjects" :key="i">
                    <a-col :span="16">
                      <a-select
                        label-in-value
                        :placeholder="$t('scolarite.choisirSubsubject')"
                        :default-value="item2.subsubject"
                        v-model="item2.subsubject"
                      >
                        <a-select-option
                          v-for="subsubject in unusedSubsubjects(item)"
                          :key="subsubject._id"
                          :value="subsubject._id"
                        >
                          {{ subsubject.name }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="6">
                      <a-input-number
                        style="width: 100%"
                        class="ml-2"
                        :value="item2.mark"
                        v-model="item2.mark"
                        :placeholder="$t('scolarite.mark')"
                      />
                    </a-col>
                    <a-col :span="2">
                      <a-button
                        type="dashed"
                        class="ml-4"
                        shape="circle"
                        icon="delete"
                        @click="
                          () => {
                            selectedSubjects[index].subsubjects.splice(i, 1);
                          }
                        "
                      />
                    </a-col>
                  </a-row>
                </div>
                <a-row style="margin-top: 0px">
                  <a-col :span="4"></a-col>
                  <a-col :span="8">
                    <a-button
                      icon="plus"
                      type="dashed"
                      class="mr-3 m-auto"
                      style="width: 250px"
                      @click="
                        () => {
                          selectedSubjects[index].subsubjects.push({
                            subsubject: undefined,
                            mark: undefined,
                          });
                        }
                      "
                    >
                      {{ $t("scolarite.ajouterSubsubject") }}
                    </a-button>
                  </a-col>
                  <a-col :span="8">
                    <a-button
                      icon="copy"
                      type="dashed"
                      class="mr-3 m-auto"
                      style="width: 250px"
                      @click="
                        () => {
                          subsubjectVisible = true;
                        }
                      "
                    >
                      {{ $t("scolarite.addNewSubsubject") }}
                    </a-button>
                  </a-col>
                  <a-col :span="4"></a-col>
                </a-row>
              </div>
            </div>
            <div class="m-0 m-auto">
              <a-row>
                <a-col :span="8"></a-col>
                <a-col :span="8">
                  <a-button
                    icon="plus"
                    type="primary"
                    block
                    class="mr-3 m-auto"
                    @click="
                      () => {
                        selectedSubjects.push({
                          subject: undefined,
                          coef: undefined,
                          subsubjects: [],
                        });
                      }
                    "
                  >
                    {{ $t("scolarite.ajouterMatiere") }}
                  </a-button>
                </a-col>
                <a-col :span="8"></a-col>
              </a-row>
            </div>
          </a-form-item>
          <div class="form-actions mt-0">
            <a-button
              type="primary"
              htmlType="submit"
              :loading="loadingClasse"
              class="mr-3"
            >
              {{ $t("action.ajouter") }}
            </a-button>
            <a-button
              key="back"
              @click="handleCancel('add')"
              :disabled="loadingClasse"
            >
              {{ $t("action.annuler") }}
            </a-button>
          </div>
        </a-form>
      </a-modal>
      <!-- edit module modal-->
      <a-modal
        v-model="visibleModal"
        @cancel="handleCancel"
        :title="
          $t('scolarite.modifierListeMatieres', { name: selectedModule.name })
        "
        :footer="false"
        width="50%"
        v-if="selectedModule"
      >
        <a-form :form="form" @submit="editModule">
          <a-row style="width: 100%">
            <a-col :span="12">
              <a-form-item :label="$t('scolarite.nom')">
                <a-input
                  :placeholder="$t('scolarite.nom')"
                  v-decorator="[
                    'name',
                    {
                      initialValue: selectedModule.name,
                      rules: [
                        {
                          required: true,
                          message: $t('requis.nom'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item :label="$t('scolarite.coefficient')">
                <a-input-number
                  style="width: 100%; margin-left: 10px"
                  :placeholder="$t('scolarite.coefficient')"
                  :value="selectedModule.coef"
                  v-decorator="[
                    'coef',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.coefficient'),
                        },
                      ],
                      initialValue: selectedModule.coef,
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item :label="$t('scolarite.listeMatieres')">
            <div
              class="card"
              v-if="selectedSubjects.length == 0"
              style="width: 100%; padding: 10px"
            >
              <a-empty :image="simpleImage" />
            </div>
            <div v-else>
              <div
                v-for="(item, index) in selectedSubjects"
                :key="index"
                class="card"
                style="width: 100%; padding: 10px"
              >
                <a-label>{{ $t("emploi.matiere") }}: </a-label>
                <a-row>
                  <a-col :span="12">
                    <a-select
                      label-in-value
                      :placeholder="$t('emploi.choisirMatiere')"
                      :default-value="item.subject"
                      v-model="item.subject"
                      @change="
                        (val) => {
                          item.subject = val;
                        }
                      "
                    >
                      <a-select-option
                        v-for="subject in unusedSubjects"
                        :key="subject._id"
                        :value="subject._id"
                      >
                        {{ subject.name }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="10">
                    <a-input-number
                      style="width: 100%"
                      class="ml-2"
                      :value="item.coef"
                      v-model="item.coef"
                      :placeholder="$t('scolarite.coefficient')"
                  /></a-col>
                  <a-col :span="2">
                    <a-button
                      type="danger"
                      class="ml-4"
                      shape="circle"
                      icon="delete"
                      @click="
                        () => {
                          selectedSubjects.splice(index, 1);
                        }
                      "
                    />
                  </a-col>
                </a-row>
                <a-label>{{ $t("scolarite.subsubjects") }}:</a-label>
                <div
                  class="card"
                  v-if="item.subsubjects && item.subsubjects.length > 0"
                  style="padding: 10px; margin-bottom: 10px"
                >
                  <a-row>
                    <a-col :span="16">
                      <a-label>{{ $t("scolarite.subsubject") }}: </a-label>
                    </a-col>
                    <a-col :span="6">
                      <a-label>{{ $t("scolarite.mark") }}: </a-label>
                    </a-col>
                  </a-row>
                  <a-row v-for="(item2, i) in item.subsubjects" :key="i">
                    <a-col :span="16">
                      <a-select
                        label-in-value
                        :placeholder="$t('scolarite.choisirSubsubject')"
                        :default-value="item2.subsubject"
                        v-model="item2.subsubject"
                      >
                        <a-select-option
                          v-for="subsubject in unusedSubsubjects(item)"
                          :key="subsubject._id"
                          :value="subsubject._id"
                        >
                          {{ subsubject.name }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="6">
                      <a-input-number
                        style="width: 100%"
                        class="ml-2"
                        :value="item2.mark"
                        v-model="item2.mark"
                        :placeholder="$t('scolarite.mark')"
                      />
                    </a-col>
                    <a-col :span="2">
                      <a-button
                        type="dashed"
                        class="ml-4"
                        shape="circle"
                        icon="delete"
                        @click="
                          () => {
                            selectedSubjects[index].subsubjects.splice(i, 1);
                          }
                        "
                      />
                    </a-col>
                  </a-row>
                </div>
                <a-row style="margin-top: 0px">
                  <a-col :span="4"></a-col>
                  <a-col :span="8">
                    <a-button
                      icon="plus"
                      type="dashed"
                      class="mr-3 m-auto"
                      style="width: 250px"
                      @click="
                        () => {
                          selectedSubjects[index].subsubjects.push({
                            subsubject: undefined,
                            mark: undefined,
                          });
                        }
                      "
                    >
                      {{ $t("scolarite.ajouterSubsubject") }}
                    </a-button>
                  </a-col>
                  <a-col :span="8">
                    <a-button
                      icon="copy"
                      type="dashed"
                      class="mr-3 m-auto"
                      style="width: 250px"
                      @click="
                        () => {
                          subsubjectVisible = true;
                        }
                      "
                    >
                      {{ $t("scolarite.addNewSubsubject") }}
                    </a-button>
                  </a-col>
                  <a-col :span="4"></a-col>
                </a-row>
              </div>
            </div>
            <div class="m-0 m-auto">
              <a-row>
                <a-col :span="8"></a-col>
                <a-col :span="8">
                  <a-button
                    icon="plus"
                    type="primary"
                    block
                    class="mr-3 m-auto"
                    @click="
                      () => {
                        selectedSubjects.push({
                          subject: undefined,
                          coef: undefined,
                          subsubjects: [],
                        });
                      }
                    "
                  >
                    {{ $t("scolarite.ajouterMatiere") }}
                  </a-button>
                </a-col>
                <a-col :span="8"></a-col>
              </a-row>
            </div>
          </a-form-item>
          <div class="form-actions mt-0">
            <a-button
              type="primary"
              htmlType="submit"
              :loading="loadingClasse"
              :disabled="loadingClasse"
              class="mr-3"
            >
              {{ $t("action.modifier") }}
            </a-button>
            <a-button
              key="back"
              @click="handleCancel('edit')"
              :disabled="loadingClasse"
            >
              {{ $t("action.annuler") }}
            </a-button>
          </div>
        </a-form>
      </a-modal>

      <a-table
        :loading="tableLeading"
        :rowKey="'_id'"
        :columns="columns"
        :data-source="data"
        :pagination="true"
        :scroll="{ x: 'max-content' }"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  affSubjects(record);
                },
              },
            };
          }
        "
      >
        <template slot="subjects" slot-scope="text, record">
          <div
            v-for="subject in record.subjects"
            :key="subject._id"
            class="arabicStyle"
          >
            -
            <a-tag color="green" class="arabicStyle"
              >{{ subject.name }} ({{
                record.subjectsInfo ? record.subjectsInfo[subject._id].coef : 1
              }})</a-tag
            >
          </div>
        </template>
        <div slot="filterDropdown" slot-scope="{ column }" style="padding: 8px">
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <span slot="operationSupprimer" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a-button :disabled="editingKey !== ''" type="danger" @click.stop>
                <a-popconfirm
                  :title="$t('requis.supp')"
                  @confirm="() => supp(record._id)"
                >
                  <a>{{ $t("action.supprimer") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
          </div>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import { Empty } from "ant-design-vue";

var modulesData = [];
export default {
  name: "classrooms",
  computed: {
    ...mapState(["settings", "user"]),
    unusedSubjects() {
      return this.listSubjects.filter(
        (s) =>
          !this.selectedSubjects.find(
            (ss) => ss.subject && ss.subject.key == s._id
          )
      );
    },
  },
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Module spécifique",
    });

    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;
    this.getModules(this.defaultLevel[this.schoolType]);

    await apiClient
      .post("/subject/filter", {
        query: {
          status: "active",
        },
      })
      .then((res) => {
        this.listSubjects = res.data.map((elem) => ({
          ...elem,
          key: elem._id,
        }));
        this.tableLoadingSubsubjects = false;
      })
      .catch(() => this.$message.error(this.$t("error.aucMatiere")));

    await apiClient
      .post("/subsubject/filter", {
        query: {
          status: "active",
        },
      })
      .then((res) => {
        this.listSubsubjects = res.data.map((elem) => ({
          ...elem,
          key: elem._id,
        }));
        this.listSubsubjectsCache = [...this.listSubsubjects];
      })
      .catch(() => this.$message.error(this.$t("error.aucSubsubject")));
  },
  data() {
    return {
      schoolType: null,
      columns: [
        {
          title: this.$t("scolarite.nomModule"),
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("scolarite.coefficient"),
          dataIndex: "coef",
          sorter: true,
          key: "coef",
        },
        {
          title: this.$t("scolarite.listeMatieres"),
          dataIndex: "subjects",
          key: "subjects",
          scopedSlots: {
            customRender: "subjects",
          },
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      subsubjectsColumns: [
        {
          title: this.$t("scolarite.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      tableLoadingSubsubjects: true,
      visibleModal: false,
      visibleAddModal: false,
      form: this.$form.createForm(this),
      addForm: this.$form.createForm(this),
      subsubjectForm: this.$form.createForm(this),
      data: null,
      subsubjectVisible: false,
      addSubsubjectVisible: false,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
      subsubjectEditingKey: "",
      selectedModule: null,
      listSubjects: [],
      listSubsubjects: [],
      listSubsubjectsCache: [],
      selectedSubjects: [],
      targetKeys: [],
      levelSelected: 1,
      targetAddKeys: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      defaultLevel: {
        ecole: "1",
        college: "7",
        lycee: "10",
        collegeEtLycee: "7",
        primaireEtCollege: "1",
      },
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
    };
  },

  methods: {
    unusedSubsubjects(subject) {
      return this.listSubsubjects.filter(
        (s) =>
          !subject.subsubjects.find(
            (ss) => ss.subsubject && ss.subsubject.key == s._id
          )
      );
    },
    async getModules(level) {
      await apiClient
        .post("/moduleVerySpecific/filter", {
          query: { status: "active", level: level },
        })
        .then((res) => {
          modulesData = [...res.data];
          if (modulesData.length == 0)
            this.$message.warning(this.$t("error.aucModule"));
          modulesData = modulesData.map((elem) => {
            return {
              ...elem,
              key: elem._id,
            };
          });
          this.data = modulesData;
          this.cacheData = modulesData.map((item) => ({ ...item }));
          this.tableLeading = false;
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
          this.tableLeading = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    editModule(e) {
      e.preventDefault();

      const thereEmpty = this.selectedSubjects.filter(
        (s) => !s.subject || !s.coef
      );

      if (
        !this.selectedSubjects ||
        this.selectedSubjects.length == 0 ||
        thereEmpty.length > 0
      ) {
        this.$message.warning(this.$t("error.matiereNonAffecte"));
        return;
      }

      for (const subject of this.selectedSubjects) {
        let marks = 0;
        for (const sub of subject.subsubjects) {
          marks += Number(sub.mark);
          if (!sub.mark || !sub.subsubject.label)
            return this.$message.error(
              this.$t("error.subsubjectNonAffecte", {
                subject: subject.subject.label,
              })
            );
        }
        if (
          subject.subsubjects &&
          subject.subsubjects.length != 0 &&
          marks != 20
        ) {
          this.$message.error(
            this.$t("scolarite.totalMarksMustBe20", {
              subject: subject.subject.label,
            })
          );
          return;
        }
      }

      this.loadingClasse = true;

      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }

        values.subjects = this.selectedSubjects.map((s) => s.subject.key);
        values.subsubjects = [];
        values.subjectsInfo = {};
        this.selectedSubjects.map((s) => {
          values.subjectsInfo[s.subject.key] = {
            coef: s.coef,
            subsubjectsMark: {},
          };
          s.subsubjects.map((sub) => {
            values.subjectsInfo[s.subject.key].subsubjectsMark[
              sub.subsubject.key
            ] = Number(sub.mark);
            values.subsubjects.push(sub.subsubject.key);
          });
        });

        apiClient
          .patch("/moduleVerySpecific/" + this.selectedModule._id, {
            data: values,
          })
          .then((res) => {
            this.selectedModule.subjects = this.listSubjects.filter((item) =>
              this.selectedSubjects.some((d) => d.subject.key === item._id)
            );
            this.selectedModule.coef = values.coef;
            this.selectedModule.name = values.name;
            this.selectedModule.subjectsInfo = values.subjectsInfo;

            var foundIndex = this.data.findIndex(
              (x) => x._id == this.selectedModule._id
            );
            this.data[foundIndex] = this.selectedModule;
            this.$message.success(
              this.$t("success.modificationsMatieresEffectue")
            );

            this.handleCancel();
          })
          .catch((e) =>
            this.$message.error(this.$t("error.impoModifiMatieres"))
          )
          .finally(() => (this.loadingClasse = false));
      });
    },
    addModule(e) {
      e.preventDefault();

      const thereEmpty = this.selectedSubjects.filter(
        (s) => !s.subject || !s.coef
      );

      if (
        !this.selectedSubjects ||
        this.selectedSubjects.length == 0 ||
        thereEmpty.length > 0
      ) {
        this.$message.warning(this.$t("error.matiereNonAffecte"));
        return;
      }

      for (const subject of this.selectedSubjects) {
        let marks = 0;
        for (const sub of subject.subsubjects) {
          marks += Number(sub.mark);
          if (!sub.mark || !sub.subsubject.label)
            return this.$message.error(
              this.$t("error.subsubjectNonAffecte", {
                subject: subject.subject.label,
              })
            );
        }
        if (
          subject.subsubjects &&
          subject.subsubjects.length != 0 &&
          marks != 20
        ) {
          this.$message.error(
            this.$t("scolarite.totalMarksMustBe20", {
              subject: subject.subject.label,
            })
          );
          return;
        }
      }

      this.addForm.validateFields((err, values) => {
        if (err) console.log(err);
        if (!err) {
          this.loadingClasse = true;

          console.log("Received values of form: ", values);
          values.subjects = this.selectedSubjects.map((s) => s.subject.key);
          values.subsubjects = [];
          values.subjectsInfo = {};
          this.selectedSubjects.map((s) => {
            values.subjectsInfo[s.subject.key] = {
              coef: s.coef,
              subsubjectsMark: {},
            };
            s.subsubjects.map((sub) => {
              values.subjectsInfo[s.subject.key].subsubjectsMark[
                sub.subsubject.key
              ] = Number(sub.mark);
              values.subsubjects.push(sub.subsubject.key);
            });
          });
          values.level = this.levelSelected;

          console.log(values);
          apiClient
            .put("/moduleVerySpecific/", {
              data: values,
            })
            .then((res) => {
              res.data.key = res.data._id;
              this.data.push(res.data);
              this.$message.success(
                this.$t("success.modificationsMatieresEffectue")
              );

              this.handleCancel();
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoModifiMatieres"))
            )
            .finally(() => {
              this.loadingClasse = false;
              this.targetAddKeys = [];
              this.visibleAddModal = false;
            });
        }
      });
    },
    affSubjects(record) {
      this.selectedModule = record;
      this.targetKeys = record.subjects.map((elem) => elem._id);
      this.selectedSubjects = [];
      record.subjects.map((elem) => {
        const subsubjects = [];
        if (
          record.subjectsInfo &&
          record.subjectsInfo[elem._id] &&
          record.subjectsInfo[elem._id].subsubjectsMark
        )
          for (const key in record.subjectsInfo[elem._id].subsubjectsMark) {
            const sub = this.listSubsubjects.find((sub) => sub._id == key);
            subsubjects.push({
              subsubject: { key, label: sub.name },
              mark: record.subjectsInfo[elem._id].subsubjectsMark[key],
            });
          }
        this.selectedSubjects.push({
          subject: { key: elem._id, label: elem.name },
          coef: record.subjectsInfo ? record.subjectsInfo[elem._id].coef : 1,
          subsubjects,
        });
      });

      this.showModal();
    },
    renderItem(item) {
      const customLabel = <span class="custom-item">{item.name}</span>;

      return {
        label: customLabel, // for displayed item
        value: item.name, // for title and filter matching
      };
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    handleAddChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetAddKeys = targetKeys;
    },
    showModal() {
      this.visibleModal = true;
    },
    showAddModal() {
      this.visibleAddModal = true;
      this.selectedSubjects = [];
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleCancel(type = "edit") {
      console.log(type);
      if (type == "edit") {
        this.visibleModal = false;
        this.form.resetFields();
      } else {
        this.addForm.resetFields();
        this.visibleAddModal = false;
        this.targetAddKeys = [];
      }
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    handleModuleChange(value) {
      this.levelSelected = value;
      this.getModules(value);
      console.log(`selected ${this.levelSelected}`);
    },

    confirmDelete(key) {
      apiClient
        .delete("/moduleVerySpecific/" + key)
        .then(() => {
          this.$message.success(this.$t("success.moduleSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.moduleNonSupp")))
        .finally(() => (this.visibleModal = false));
    },

    supp(key) {
      this.confirmDelete(key);

      // const classroomsUsed = [];

      // apiClient
      //   .post("/schedules/filter", {
      //     query: {
      //       type: "MS",
      //       moduleSpecific: key,
      //       status: { $ne: "inactive" },
      //     },
      //     aggregation: [
      //       {
      //         $lookup: {
      //           from: "classrooms",
      //           localField: "classeRoom",
      //           foreignField: "_id",
      //           as: "classeRoom",
      //         },
      //       },
      //       {
      //         $project: {
      //           _id: 1,
      //           classeRoom: {
      //             $let: {
      //               vars: {
      //                 classVar: {
      //                   $arrayElemAt: ["$classeRoom", 0],
      //                 },
      //               },
      //               in: {
      //                 name: "$$classVar.name",
      //                 _id: "$$classVar._id",
      //               },
      //             },
      //           },
      //         },
      //       },
      //     ],
      //   })
      //   .then(({ data }) => {
      //     data.map((sched) => {
      //       if (sched.classeRoom && sched.classeRoom.name)
      //         classroomsUsed.push(sched.classeRoom.name);
      //     });
      //     if (classroomsUsed.length > 0)
      //       Modal.confirm({
      //         title: this.$t("scolarite.confirmDeleteModule"),
      //         content: (h) => {
      //           let list = [];
      //           classroomsUsed.map((s) => {
      //             list.push(h("li", s));
      //           });
      //           return h("ul", list);
      //         },
      //         okText: this.$t("action.supprimer"),
      //         okType: "danger",
      //         cancelText: this.$t("action.annuler"),
      //         onOk: () => {
      //           this.confirmDelete(key);
      //         },
      //         onCancel() { },
      //       });
      //     else {
      //       this.confirmDelete(key);
      //     }
      //   })
      //   .catch((e) => {
      //     this.$message.error(this.$t("error.moduleNonSupp"));
      //     console.log(e);
      //   })
      //   .finally(() => (this.visibleModal = false));
    },

    closeAddSubsubject() {
      this.addSubsubjectVisible = false;
      this.subsubjectForm.resetFields();
    },

    addSubsubject(e) {
      e.preventDefault();
      this.subsubjectForm.validateFieldsAndScroll((err, values) => {
        console.log(values);
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/subsubject", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.matiereAjout"));
              this.listSubsubjects.push(res.data);
            })
            .catch((e) => {
              this.$message.error(this.$t("error.matiereNonAjoute"));
              console.log(e);
            })
            .finally(() => {
              this.loadingClasse = false;
              this.closeAddSubsubject();
            });
        }
      });
      this.loadingClasse = false;
    },

    handleChangeSubsubject(value, key, column) {
      const newData = [...this.listSubsubjects];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.listSubsubjects = newData;
      }
    },
    editSubsubject(key) {
      const newData = [...this.listSubsubjects];
      const target = newData.filter((item) => key === item.key)[0];
      this.subsubjectEditingKey = key;
      if (target) {
        target.editable = true;
        this.listSubsubjects = newData;
      }
    },
    saveSubsubject(key) {
      const newData = [...this.listSubsubjects];
      const newCacheData = [...this.listSubsubjectsCache];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = { ...target };
      delete updates.key;
      apiClient
        .patch("/subsubject/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.listSubsubjects = newData;
            Object.assign(targetCache, target);
            this.listSubsubjectsCache = newCacheData;
          }
          this.subsubjectEditingKey = "";
          this.$message.success(this.$t("success.matiereMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.matiereNonMSA"));
          this.cancel(key);
        });
    },
    cancelSubsubject(key) {
      const newData = [...this.listSubsubjects];
      const target = newData.filter((item) => key === item.key)[0];
      this.subsubjectEditingKey = "";
      if (target) {
        Object.assign(
          target,
          this.listSubsubjectsCache.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.listSubsubjects = newData;
      }
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arabicStyle {
  direction: rtl;
  font-size: 20px;
}

.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}
</style>
>
